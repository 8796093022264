import '../../Styles/DashboardComponents/Messages/inbox.css'
import { useEffect, useState } from 'react';
import { fetchLink } from '../../config'
import CircularProgress from '@material-ui/core/CircularProgress';
import CachedIcon from '@material-ui/icons/Cached';
import BlankMessage from './Messages/BlankMessage' 
import DeleteIcon from '@material-ui/icons/Delete';
import ResponseSended from './Messages/ResponseSended';

const Messages = () => {

    const [inboxType, setInboxType] = useState(0)
    
    const [newMessages, setNewMessages] = useState([])
    const [newMessagesReady, setNewMessagesReady] = useState(false)
    const [feedbackedMessages, setFeedbackedMessages] = useState([])
    const [feedbackedMessagesReady, setFeedbackedMessagesReady] = useState(false)

    const [messageDetails, setMessageDetails] = useState([])

    useEffect(() => {
        fetch(`${fetchLink}/getnewmessages`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then((data) => { setNewMessages(data); setNewMessagesReady(true) })

        fetch(`${fetchLink}/getfeedbackedmessages`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then((data) => { setFeedbackedMessages(data); setFeedbackedMessagesReady(true) })
    }, [])

    const handleFetchAgain = () => {
        setNewMessagesReady(false)
        setFeedbackedMessagesReady(false)
        setMessageDetails([])

        fetch(`${fetchLink}/getnewmessages`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then((data) => { setNewMessages(data); setNewMessagesReady(true) })

        fetch(`${fetchLink}/getfeedbackedmessages`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then((data) => { setFeedbackedMessages(data); setFeedbackedMessagesReady(true) })
    }

    const handleSetDetails = (details) => {
        setMessageDetails(details)
        setWantDelete(false)
    }

    const [userResponse, setUserResponse] = useState('')
    const handleSubmitUserResponse = (e) => {
        e.preventDefault();
        setResponseSended(true)
        const id = messageDetails.id
        fetch(`${fetchLink}/senduserresponse`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({userResponse, id})
        })

        setTimeout(() => {
            setResponseSended(false)
            setMessageDetails([])
            handleFetchAgain()
        }, 1000)
    }
    const handleSetUserResponse = (e) => {
        const value = e.target.value;
        setUserResponse(value)
    }

    const [wantDelete, setWantDelete] = useState(false)
    const handleDeleteMessage = (id) => {
        fetch(`${fetchLink}/deletemessage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id})
        })
        setMessageDetails([])
        setNewMessagesReady(false)
        setFeedbackedMessagesReady(false)
        setTimeout(() => {
            handleFetchAgain()
        }, 1000)
    }

    const [responseSended, setResponseSended] = useState(false)

    return ( 
        <div className="messages__wrapper">
            <h2>Wiadomości z formularza kontaktowego</h2>
            <div className="inbox">
                <div className="inbox--header">
                    <ul>
                        <li id={inboxType === 0 ? 'inboxActive' : null} onClick={() => setInboxType(0)}>Nowe</li>
                        <li id={inboxType === 1 ? 'inboxActive' : null} onClick={() => setInboxType(1)}>Zakończone</li>
                    </ul>
                    <span onClick={handleFetchAgain}><CachedIcon /> Odśwież</span>
                </div>
                <div className="inbox--messages">
                    { inboxType === 0 ? <ShowNewMessages messages={newMessages} ready={newMessagesReady} details={handleSetDetails} /> : <ShowFeedbackedMessages messages={feedbackedMessages} ready={feedbackedMessagesReady} details={handleSetDetails}/> }
                </div>
                { messageDetails.length === 0 ? null : <div className="inbox--details">
                    { responseSended ? <ResponseSended />  : <> 
                    <span>Dane osobowe</span>
                    <ul>
                        <li>{messageDetails.name} {messageDetails.surname}</li>
                        <li>{messageDetails.email}</li>
                        <li>{messageDetails.tel}</li>
                    </ul>
                    <span>Wiadomość</span>
                    <p>{messageDetails.message}</p>
                    <span style={{marginBottom: '.5em', display: 'block'}}>{ messageDetails.feedback === 0 ? 'Wyślij odpowiedź' : 'Udzielona odpowiedź' }</span>
                    { messageDetails.feedback === 0 ? <><form onSubmit={handleSubmitUserResponse}>
                        <textarea onChange={handleSetUserResponse}>

                        </textarea>
                        <button>Wyślij &rarr;</button>
                    </form>
                    <div className="deleteIcon" onClick={() => setWantDelete(true)}>
                        <DeleteIcon />
                    </div> </> : <p>{messageDetails.feedbackContent}</p> }
                    { wantDelete ? <div className="confirmDelete">
                        <h2>Czy napewno chcesz usunąć wiadomość?</h2>
                        <button onClick={() => setWantDelete(false)}>Anuluj</button>
                        <button onClick={() => handleDeleteMessage(messageDetails.id)}>Usuń</button>
                    </div> : null }
                    </> }
                </div>  }
            </div>
        </div>
     );
}
 
export default Messages;

const ShowNewMessages = ({ messages, ready, details }) => {

    const displayDate = (date) => {
        const nd = new Date(date)
        const year = nd.getFullYear();
        let month = nd.getMonth() + 1;
        if(month < 10) {
            month = `0${month}`
        }
        let day = nd.getDate();
        if(day < 10) {
            day = `0${day}`
        }
    
        let hour = nd.getHours()
        if(hour < 10) {
            hour = `0${hour}`
        }
        let mins = nd.getMinutes();
        if(mins < 10) {
            mins = `0${mins}`
        }
    
        const displayDate = `${year}-${month}-${day} ${hour}:${mins}`
        return displayDate
    }  

    return ( 
        <div className="display--messages">
            { ready ? null : <CircularProgress style={{width: '150px', height: '150px', color: '#3b5998', display: 'block', margin: '65px auto'}}/> }
            { messages && messages.map(message => ( <div key={message.id} className="message--item" onClick={() => details(message)}>
                <div className="message--item--content">
                    <b>{message.name} {message.surname}</b>
                    <p>{message.message}</p>
                </div>
                <div className="message--item--date">
                    { displayDate(message.sendDate) }
                </div>
            </div> )) }
            { ready && messages.length === 0 ? <BlankMessage /> : null}
        </div>
     );
}

const displayDate = (date) => {
    const nd = new Date(date)
    const year = nd.getFullYear();
    let month = nd.getMonth() + 1;
    if(month < 10) {
        month = `0${month}`
    }
    let day = nd.getDate();
    if(day < 10) {
        day = `0${day}`
    }

    let hour = nd.getHours()
    if(hour < 10) {
        hour = `0${hour}`
    }
    let mins = nd.getMinutes();
    if(mins < 10) {
        mins = `0${mins}`
    }

    const displayDate = `${year}-${month}-${day} ${hour}:${mins}`
    return displayDate
}  
 
const ShowFeedbackedMessages = ({ messages, ready, details }) => {
    return ( 
        <div className="display--messages">
            { ready ? null : <CircularProgress style={{width: '150px', height: '150px', color: '#3b5998', display: 'block', margin: '65px auto'}}/> }
            { messages && messages.map(message => ( <div key={message.id} className="message--item" onClick={() => details(message)}>
                <div className="message--item--content">
                    <b>{message.name} {message.surname}</b>
                    <p>{message.message}</p>
                </div>
                <div className="message--item--date">
                    { displayDate(message.sendDate) }
                </div>
            </div> )) }
            { ready && messages.length === 0 ? <BlankMessage /> : null}
        </div>
     );
}