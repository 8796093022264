import { useEffect, useState } from "react";
import { fetchLink } from "../../config";
import StreetsLoading from "./Streets/StreetsLoading";
import '../../Styles/DashboardComponents/Metrics/streetList.css'
import '../../Styles/DashboardComponents/Metrics/streetItem.css'
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';

const Metrics = () => {

    const [streetList, setStreetList] = useState([])
    const [streetListLoaded, setStreetListLoaded] = useState(false)

    useEffect(() => {
        handleFetchPostCodes()
    }, [])

    const handleFetchPostCodes = () => {
        fetch(`${fetchLink}/getStreetList`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            setStreetList(data.results)
            setStreetListLoaded(data.isLoaded)
        })
    }

    const [streetName, setStreetName] = useState('')
    const handleSetStreetName = (e) => {
        setStreetName(e.target.value)
    }

    const handleFetchNameFiltredList = () => {
        setStreetListLoaded(false)
        fetch(`${fetchLink}/getNameFiltredStreets`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ streetName })
        })
        .then(response => response.json())
        .then(data => {
            setStreetList(data.results)
            setStreetListLoaded(data.isLoaded)
        })
    }

    const handleRefreshList = () => {
        setStreetListLoaded(false)
        setStreetName('')
        handleFetchPostCodes()
    }

    const handleClearNameForm = () => {
        setStreetName('')
    }

    const [isAddMenuOpen, setIsAddMenuOpen] = useState(false)
    const handleOpenAddMenu = () => {
        setIsAddMenuOpen(!isAddMenuOpen)
    }

    const [city, setCity] = useState('')
    const handleSetCity = (e) => {
        setCity(e.target.value)
    }
    const [street, setStreet] = useState('')
    const handleSetStreet = (e) => {
        setStreet(e.target.value)
    }
    const [postCode, setPostCode] = useState('')
    const handleSetPostCode = (e) => {
        setPostCode(e.target.value)
    }
    const [price, setPrice] = useState('')
    const handleSetPrice = (e) => {
        setPrice(e.target.value)
    }
    const [min, setMin] = useState('')
    const handleSetMin = (e) => {
        setMin(e.target.value)
    }

    const [isAdded, setIsAdded] = useState(false)

    const handleAddNewStreet = (e) => {
        e.preventDefault();
        setIsAdded(true)
        const addObject = {
            city, street, postCode, price, min
        }
        fetch(`${fetchLink}/addNewStreet`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(addObject)
        })
    }

    const handleAddAgain = () => {
        setIsAdded(false)
        setCity('')
        setStreet('')
        setPostCode('')
        setPrice('')
        setMin('')
    }

    return ( 
        <div className="metric__wrapper">
            <h2>Edycja ulic</h2>
            <div className="filterStreet">
                <span>Wyszukiwanie ulicy po nazwie</span>
                <label>
                    <input type="text" value={streetName} onChange={handleSetStreetName}/>
                    <button onClick={handleFetchNameFiltredList}><SearchIcon />Wyszukaj</button>
                    { streetName.length === 0 ? null : <div className="deleteInputValue" onClick={handleClearNameForm}><CloseIcon/></div> }
                </label>
                <div className="ctrForm">
                    <div className="ctrForm--button" onClick={handleRefreshList}>
                        <RefreshIcon/> Odśwież listę
                    </div>
                    <div className="ctrForm--button" onClick={handleOpenAddMenu}>
                       { !isAddMenuOpen ? <> <AddIcon /> Dodaj nową ulicę</> : <><VisibilityOffIcon/> Ukryj menu dodawania</> }
                    </div>
                </div>
            </div>
            { isAddMenuOpen ? <div className="addNewStreet">
              { isAdded ? <div className="addedBox">
                    <h2>Dodano ulicę do bazy danych!</h2>
                    <button onClick={handleAddAgain}>Wróc do dodawania</button>
              </div> :  <form onSubmit={handleAddNewStreet}>
                    <label>Miasto</label>
                    <input type="text" value={city} onChange={handleSetCity}/>
                    <label>Ulica</label>
                    <input type="text" value={street} onChange={handleSetStreet}/>
                    <label>Kod pocztowy</label>
                    <input type="text" value={postCode} onChange={handleSetPostCode}/>
                    <label>Cena dowozu</label>
                    <input type="text" value={price} onChange={handleSetPrice}/>
                    <label>Minimalna wartość zamówienia</label>
                    <input type="text" value={min} onChange={handleSetMin}/>
                    <button>Dodaj nową ulicę</button>
                </form> }
            </div> : null }
            <div className="listWrapper">
                <span>Lista ulic:</span>
                { streetListLoaded ? streetList.map(listItem => <DisplayStreetItem key={listItem.id} data={listItem} refreshName={handleFetchNameFiltredList}/>) : <StreetsLoading /> }
            </div>
        </div>
     );
}
 
export default Metrics;

const DisplayStreetItem = (props) => {

    const data = props.data;
    const [isMod, setIsMod] = useState(false)
    const handleChangeMod = () => {
        setIsMod(true)
    }

    const [city, setCity] = useState(data.city)
    const handleSetCity = (e) => {
        setCity(e.target.value)
    }

    const [street, setStreet] = useState(data.street)
    const handleSetStreet = (e) => {
        setStreet(e.target.value)
    } 

    const [postCode, setPostCode] = useState(data.postCode)
    const handleSetPostCode = (e) => {
        setPostCode(e.target.value)
    }

    const [price, setPrice] = useState(data.deliveryPrice)
    const handleSetPrice = (e) => {
        setPrice(e.target.value)
    }

    const [min, setMin] = useState(data.minimalOrderPrice)
    const handleSetMin = (e) => {
        setMin(e.target.value)
    }

    const handleSaveChanges = () => {
        setIsMod(false)
        const changeObject = {
            city, street, postCode, price, min,
            id: data.id
        }
        fetch(`${fetchLink}/changeStreet`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(changeObject)
        })
    }

    const [deletePopUp, setDeletePopUp] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const handleConfirmDelete = () => {
        setDeletePopUp(true)
    }
    const handleRejectDelete = () => {
        setDeletePopUp(false)
    }
    const handleDeleteStreet = () => {
        fetch(`${fetchLink}/deleteStreet`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ streetId: data.id })
        })
        setIsDeleted(true)
    }
    const handleClosePop = () => {
        props.refreshName()
        setDeletePopUp(false)
        setIsDeleted(false)
    }

    return ( 
        <div className="streetItem">
            <div className="streetItem--city">
                { isMod ? <input type="text" value={city} onChange={handleSetCity}/> : city }
            </div>
            <div className="streetItem--street">
            { isMod ? <input type="text" value={street} onChange={handleSetStreet}/> : street }
            </div>
            <div className="streetItem--code">
                { isMod ? <input type="text" value={postCode} onChange={handleSetPostCode}/> : postCode }
            </div>
            <div className="streetItem--price">
                { isMod ? <input type="text" value={price} onChange={handleSetPrice}/> : price }
            </div>
            <div className="streetItem--min">
                { isMod ? <input type="text" value={min} onChange={handleSetMin}/> : min }
            </div>
            <div className="streetItem--modify">
                { isMod ? <SaveIcon onClick={handleSaveChanges}/> : <EditIcon onClick={handleChangeMod}/> }
            </div>
            <div className="streetItem--delete">
                <DeleteIcon onClick={handleConfirmDelete}/> 
            </div>
            { deletePopUp ? <div className="confirmDeleteStreet">
                <div className="deleteBox">
                    { isDeleted ? <>
                    <h2>Usunięto ulicę</h2>
                    <button id='closePopBtn' onClick={handleClosePop}>Zamknij PopUp</button>
                    </> : <> 
                    <h2>Chcesz usunąć ulicę {data.street} w {data.city} ?</h2>
                    <button onClick={handleRejectDelete}>Anuluj</button>
                    <button onClick={handleDeleteStreet}>Usuń</button> </> }
                </div>
            </div> : null }
        </div>
     );
}