import '../Styles/login.css'
import { useState, useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchLink } from '../config'
import { AppContext } from '../Context/AppContext'
import Cookies from 'universal-cookie'

const Login = () => {

    const { setIsUserLogged } = useContext(AppContext)

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    const [formResponse, setFormResponse] = useState('')
    
    const handleSetLogin = (e) => {
        const value = e.target.value;
        setLogin(value)
    }
    const handleSetPassword = (e) => {
        const value = e.target.value;
        setPassword(value)
    }
    const handleSubmitLogin = (e) => {
        e.preventDefault();
        setFormResponse('loading')
        const loginObject = {
            login, password
        }
        fetch(`${fetchLink}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginObject)
        })
        .then(response => response.json())
        .then(data => setLoginData(data))
    }

    const setLoginData = (data) => {
        const cookies = new Cookies();
        setFormResponse(data.loginResponse)
        if(data.loginResponse === 'correct') {
            setIsUserLogged(true)
            cookies.set('adminlogged', true, {
                maxAge: 5184000
            })
            cookies.set('admincode', data.data.auth, {
                maxAge: 5184000
            })
        }
    }

    const handleTryAgain = () => {
        setFormResponse('')
    }

    return ( 
        <div className="login__wrapper">
            { formResponse === '' ?  
            <form onSubmit={handleSubmitLogin}>
                <h2>Szisza Kebab CMS</h2>
                <label>Login</label>
                <input type="text" value={login} onChange={handleSetLogin}/>
                <label>Hasło</label>
                <input type="password" value={password} onChange={handleSetPassword}/>
                <button>Zaloguj się</button>
            </form> : null }
            { formResponse === 'loading' ? <CircularProgress style={{width: '150px', height: '150px', color: 'white'}}/> : null }
            { formResponse === 'error' ? <LoginError tryagain={handleTryAgain}/> : null }
        </div>
     );
}
 
export default Login;

const LoginError = (props) => {
    return ( 
        <form>
            <h2>Wystąpił błąd</h2>
            <p>Nieprawidłowe hasło lub login</p>
            <button type='button' onClick={props.tryagain}>Spróbuj ponownie</button>
        </form>
     );
}