import '../../Styles/DashboardComponents/Main/main.css'
import { useContext } from 'react';
import { AppContext } from '../../Context/AppContext';
import CircularProgress from '@material-ui/core/CircularProgress';

const Main = () => {

    const { accountNumber, accountNumberLoaded,
            ordersNumber, ordersNumberLoaded,
            rejectedNumber, rejectedNumberLoaded,
            allEarned, allEarnedLoaded,
            cartPayment, cartPaymentLoaded,
            cashPayment, cashPaymentLoaded
    } = useContext(AppContext)

    return ( 
        <div className="main__wrapper">
            <h2>Statystyki klientów</h2>
            <div className="swipeStatsBox">
                <div className="statBox">
                    { accountNumberLoaded ? <> 
                    <h1>{accountNumber}</h1>
                    <label>Liczba kont w aplikacji</label>
                    </> : <CircularProgress color='secondary'/> }
                </div>
            </div>

            <h2>Statystki zamówień</h2>
            <div className="swipeStatsBox">
                <div className="statBox">
                { ordersNumberLoaded ? <> 
                    <h1>{ordersNumber}</h1>
                    <label>Zamówienia zrealizowane</label>
                    </> : <CircularProgress color='secondary'/> }
                </div>

                <div className="statBox">
                { rejectedNumberLoaded ? <> 
                    <h1>{rejectedNumber}</h1>
                    <label>Zamówienia odrzucone</label>
                    </> : <CircularProgress color='secondary'/> }
                </div>

                <div className="statBox">
                { allEarnedLoaded ? <> 
                    <h1>{allEarned || 0} zł</h1>
                    <label>Całkowity przychód aplikacji</label>
                    </> : <CircularProgress color='secondary'/> }
                </div>

                <div className="statBox">
                { allEarnedLoaded && ordersNumberLoaded ? <> 
                    <h1>{Math.round(allEarned / ordersNumber * 100) / 100 || 0} zł</h1>
                    <label>Średnia wartość paragonu</label>
                    </> : <CircularProgress color='secondary'/> }
                </div>

                <div className="statBox">
                { cashPaymentLoaded ? <> 
                    <h1>{Math.round(cashPayment * 100) / 100} zł</h1>
                    <label>Zapłacone gotówką w zł</label>
                    </> : <CircularProgress color='secondary'/> }
                </div>

                <div className="statBox">
                { cartPaymentLoaded ? <> 
                    <h1>{Math.round(cartPayment * 100) / 100} zł</h1>
                    <label>Zapłacone kartą w zł</label>
                    </> : <CircularProgress color='secondary'/> }
                </div>
            </div>
            
        </div>
     );
}
 
export default Main;