import { Switch, BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { useContext } from 'react';
import { AppContext } from './Context/AppContext';

import Login from './Components/Login';
import Dashboard from './Components/Dashboard'

const App = () => {

  const { isUserLogged } = useContext(AppContext)

  return ( 
    <div className="wrapper">
      <Router>
        <Switch>
          <Route exact path='/'>
            { isUserLogged ? <Redirect to='/admin' /> :  <Login /> }
          </Route>
          <Route exact path='/admin*'>
            { isUserLogged ? <Dashboard /> : <Redirect to='/' /> }
          </Route>
        </Switch>
      </Router>
    </div>
   );
}
 
export default App;
