import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const ResponseSended = () => {
    return ( 
        <div className='responseSended'>
        <CheckCircleIcon /> <h2>Wysłano odpowiedź</h2>
        </div>
     );
}
 
export default ResponseSended;