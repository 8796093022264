import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../Context/AppContext';
import Select from 'react-select'
import '../../Styles/DashboardComponents/Payments/payment.css'
import SearchIcon from '@material-ui/icons/Search';
import { fetchLink } from '../../config';
import CircularProgress from '@material-ui/core/CircularProgress';

const Payments = () => {

    const { options } = useContext(AppContext)
    const [choosedMonth, setChoosedMonth] = useState(options[0])
    const handleSetChoosedMonth = (e) => {
        setChoosedMonth(e)
    }

    const handleFetchNewDate = () => {
        handleFetchMonthEarned()
        handleFetchRejected()
        handleFetchAllMoney()
        handleFetchCartPayments()
        handleFetchCashPayment()
    }

    const [monthEarned, setMonthEarned] = useState('')
    const [monthEarnedLoaded, setMonthEarnedLoaded] = useState(false)

    const [rejectedOrders, setRejectedOrders] = useState('')
    const [rejectedOrdersLoaded, setRejectedOrdersLoaded] = useState(false)

    const [allMoney, setAllMoney] = useState('')
    const [allMoneyLoaded, setAllMoneyLoaded] = useState(false)

    const [cashPayment, setCashPayment] = useState('')
    const [cashPaymentLoaded, setCashPaymentLoaded] = useState(false)

    const [cartPayment, setCartPayment] = useState('')
    const [cartPaymentLoaded, setCartPaymentLoaded] = useState(false)

    const handleFetchMonthEarned = () => {
        const dateSearch = {
            date: choosedMonth.value
        }
        fetch(`${fetchLink}/pay/getMonthEarned`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dateSearch)
        })
        .then(response => response.json())
        .then(data => {
            setMonthEarned(data.data)
            setMonthEarnedLoaded(data.responseReady)
        })
    }

    const handleFetchRejected = () => {
        const dateSearch = {
            date: choosedMonth.value
        }
        fetch(`${fetchLink}/pay/getRejected`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dateSearch)
        })
        .then(response => response.json())
        .then(data => {
            setRejectedOrders(data.data)
            setRejectedOrdersLoaded(data.responseReady)
        })
    }

    const handleFetchAllMoney = () => {
        const dateSearch = {
            date: choosedMonth.value
        }
        fetch(`${fetchLink}/pay/getAllMoney`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dateSearch)
        })
        .then(response => response.json())
        .then(data => {
            setAllMoney(data.data)
            setAllMoneyLoaded(data.responseReady)
        })
    }

    const handleFetchCartPayments = () => {
        const dateSearch = {
            date: choosedMonth.value
        }
        fetch(`${fetchLink}/pay/cartPayment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dateSearch)
        })
        .then(response => response.json())
        .then(data => {
            setCartPayment(data.data)
            setCartPaymentLoaded(data.responseReady)
        })
    }

    const handleFetchCashPayment = () => {
        const dateSearch = {
            date: choosedMonth.value
        }
        fetch(`${fetchLink}/pay/cashPayment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dateSearch)
        })
        .then(response => response.json())
        .then(data => {
            setCashPayment(data.data)
            setCashPaymentLoaded(data.responseReady)
        })
    }

    useEffect(() => {
        handleFetchMonthEarned()
        handleFetchRejected()
        handleFetchAllMoney()
        handleFetchCartPayments()
        handleFetchCashPayment()
        // eslint-disable-next-line
    }, [])
    
    return ( 
        <>
        <h2>Twoje rozliczenia z aplikacji</h2>
        <div className="manageEarn">
            <span>Wybierz miesiąc rozliczeniowy</span>
            <label>
                <Select options={options} value={choosedMonth} onChange={handleSetChoosedMonth}/>
                <button onClick={handleFetchNewDate}><SearchIcon />Szukaj</button>
            </label>

            <div className="resultsStats">
                <div className="resultBox">
                    { allMoneyLoaded ? <>
                        <h1>{allMoney === null ? 0 : allMoney} zł</h1>
                        <span>Przychód aplikacji w danym miesiącu</span>
                    </> : <CircularProgress color='secondary' /> }
                </div>

                <div className="resultBox">
                    { allMoneyLoaded ? <>
                        <h1>{allMoney === null ? 0 : allMoney/10} zł</h1>
                        <span>Prowizja właściciela aplikacji w danym miesiącu</span>
                    </> : <CircularProgress color='secondary' /> }
                </div>

                <div className="resultBox">
                    { allMoneyLoaded && monthEarnedLoaded ? <>
                        <h1>{allMoney === null ? 0 : Math.round(allMoney / monthEarned * 100) / 100} zł</h1>
                        <span>Średnia wartość zamówienia w danym miesiącu</span>
                    </> : <CircularProgress color='secondary' /> }
                </div>

                <div className="resultBox">
                    { monthEarnedLoaded ? <>
                        <h1>{monthEarned}</h1>
                        <span>Ilość zrealizowanych zamówień w danym miesiącu</span>
                    </> : <CircularProgress color='secondary' /> }
                </div>

                <div className="resultBox">
                    { rejectedOrdersLoaded ? <>
                        <h1>{rejectedOrders}</h1>
                        <span>Ilość odrzuconych zamówień w danym miesiącu</span>
                    </> : <CircularProgress color='secondary' /> }
                </div>

                <div className="resultBox">
                    { cashPaymentLoaded ? <>
                        <h1>{cashPayment || 0} zł</h1>
                        <span>Suma płatności gotówką</span>
                    </> : <CircularProgress color='secondary' /> }
                </div>

                <div className="resultBox">
                    { cartPaymentLoaded ? <>
                        <h1>{cartPayment || 0} zł</h1>
                        <span>Suma płatności kartą</span>
                    </> : <CircularProgress color='secondary' /> }
                </div>
            </div>
        </div>
       
        </>
     );
}
 
export default Payments;