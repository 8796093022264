import CircularProgress from '@mui/material/CircularProgress';
import '../../../Styles/DashboardComponents/Metrics/loading.css'

const StreetsLoading = () => {
    return ( 
        <div className='streetLoading'>
            <CircularProgress style={{ width: '100px', height: '100px', color: '#3b5998' }}/>
        </div>
     );
}
 
export default StreetsLoading;