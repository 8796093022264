import MailOutlineIcon from '@material-ui/icons/MailOutline';

const BlankMessage = () => {
    return ( 
        <div className="blank--message">
            <MailOutlineIcon />
            <h2>Brak wiadomości</h2>
        </div>
     );
}
 
export default BlankMessage;