import Select from 'react-select'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../Context/AppContext'  
import '../../Styles/DashboardComponents/List/lists.css'
import '../../Styles/DashboardComponents/List/clientitem.css'
import '../../Styles/DashboardComponents/List/orderDisplay.css'
import CircularProgress from '@mui/material/CircularProgress';
import { fetchLink } from '../../config';

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const options = [
    { value: '0', label: 'Pokaż zrealizowane zamówienia' },
    { value: '1', label: 'Pokaż odrzucone zamówienia' },
    { value: '2', label: 'Pokaż zrealizowane zamówienia płatne gotówką' },
    { value: '3', label: 'Pokaż zrealizowane zamówienia płatne kartą' },
    { value: '4', label: 'Pokaż listę kont' },
]

const List = () => {

    const [listData, setListData] = useState([])
    const [listDataLoaded, setListDataLoaded] = useState(false)
    const [optionChoosed, setOptionChoosed] = useState(0)

    useEffect(() => {
        handleFetchData({ value: 0 })
    }, [])

    const handleFetchData = (e) => {
        setListDataLoaded(false)
        setOptionChoosed(e.value)
        fetch(`${fetchLink}/getOrderList`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ option: e.value })
        })
        .then(response => response.json())
        .then(data => {
            setListDataLoaded(data.loaded)
            setListData(data.res)
        })
    }

    return ( 
        <>
        <h2 id='listHead'>Lista zamówień</h2>
        <Select options={options} onChange={handleFetchData} defaultValue={options[0]}/>
        <div className="listWrapp">
        { listDataLoaded ? listData.length === 0 ? <ListBlank /> : listData.map(item => <DisplayItem key={item.id} data={item} choosed={optionChoosed} fetch={handleFetchData}/>) : <div className='loading'><CircularProgress style={{ width: '100px', height: '100px', color: '#3b5998' }}/></div> }
        </div>
        </>
     );
}
 
export default List;

const DisplayItem = (props) => {

    const data = props.data;
    
    const handleEnteryDate = () => {
        const DO = new Date()
        const ED = new Date(data.createDate)
        let stringToDisplay = ''
        if(((DO-ED) / 86400000) < 1) {
            stringToDisplay = 'dzisiaj'
        }   else if(((DO-ED) / 86400000) > 1 && ((DO-ED) / 86400000) < 2){
            stringToDisplay = 'wczoraj'
        }   else {
            stringToDisplay = `${new Date(DO-ED).getDate()} dni temu`
        }
        return stringToDisplay
    }

    const [editMode, setEditMode] = useState(false)

    const [street, setStreet] = useState(data.street)
    const handleSetStreet = (e) => {
        setStreet(e.target.value)
    }
    const [homeNumber, setHomeNumber] = useState(data.homeNumber)
    const handleSetHomeNumber = (e) => {
        setHomeNumber(e.target.value)
    }
    const [flatNumber, setFlatNumber] = useState(data.flatNumber)
    const handleSetFlatNumber = (e) => {
        setFlatNumber(e.target.value)
    }

    const handleChangeData = (e) => {
        e.preventDefault();
        const changeObject = {
            id: data.id,
            street,
            homeNumber,
            flatNumber
        }
        fetch(`${fetchLink}/changeUserData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(changeObject)
        })
        .then(() => {
            setEditMode(false)
            props.fetch({ value: '4' })
        })
    }

    //order displa

    const handleDisplayOrderDate = () => {
        const DO = new Date(data.orderDate)
        const year = DO.getFullYear()
        let month = DO.getMonth()
        if(month < 10) {
            month = `0${month}`
        }
        let day = DO.getDate()
        if(day < 10) {
            day = `0${day}`
        }
        let hour = DO.getHours()
        if(hour < 10) {
            hour = `0${hour}`
        }
        let mins = DO.getMinutes()
        if(mins < 10) {
            mins = `0${mins}`
        }
        const TimeDisplay = `${year}-${month}-${day} ${hour}:${mins}`
        return TimeDisplay
    }
    console.log(data);

    return ( 
        <>
        { props.choosed === '4' ? 
        <div className="clientListItem">
            { editMode ? 
            <>
                <form className="clientAdressEdit" onSubmit={handleChangeData}>
                    <label>Edycja ulicy</label>
                    <input type="text" value={street} onChange={handleSetStreet}/>
                    <label>Edycja nr. domu</label>
                    <input type="text" value={homeNumber} onChange={handleSetHomeNumber}/>
                    <label>Edycja nr. mieszkania</label>
                    <input type="text" value={flatNumber} onChange={handleSetFlatNumber}/>
                    <button>Zapisz zmiany</button>
                </form>
            </>
            : <>
            <p className="clientName">{data.name} {data.surname} <span>Dołączył: {handleEnteryDate()}</span></p>
            <div className="clientContact">
                ul. {data.street} nr. {data.homeNumber}{ data.flatNumber !== '' ? `/${data.flatNumber}` : null } {data.city}
            </div>
            <div className="clientAdress">
                <span><PhoneIcon /> {data.number}</span>
                <span><EmailIcon /> {data.email}</span>
            </div>
           { data.verified ? <div className="verified"><MarkEmailReadIcon /></div> : <div className="notverified"><ReportGmailerrorredIcon /></div> }
           <div className="edit" onClick={() => setEditMode(true)}><EditIcon /></div>
        </> }
        </div>
        : 
            <div className="orderDisplay">
                { data.haveAccount ? <div className="haveAccount"><AccountCircleIcon /></div> : null }
                <div className="orderDisplay--client">
                    <span>Dane klienta</span>
                    <p>{data.firstname} {data.surname}</p>
                    <p>ul. {data.street} nr. {data.homenumber}{ data.flatNumber !== '' ? `/${data.flatNumber}` : null }</p>
                    <p>{data.phone}</p>
                    <p>{data.email}</p>
                </div>
                <div className="orderDisplay--delivery">
                    <span>Dane zamówienia</span>
                    <p>Zamówiono dnia: {handleDisplayOrderDate()}</p>
                    <p>Sposób dostarczenia: {data.deliveryType === "1" ? 'Dowóz' : 'Odbiór osobisty'}</p>
                    <p>Płatność: {data.paymentType === "1" ? 'Karta lub blik' : "Gotówka"}</p>
                </div>
                <div className="orderDisplay--products">
                    <span>Produkty zamówione</span>
                    { data.products && JSON.parse(data.products).map((product, i) => <DisplayOrderedProduct key={i} data={product} lister={i}/>) }
                </div>
                { data.deliveryType === "1" ? <b>Cena dowozu: {data.deliveryValue}zł</b> : null }
                <b>Cena do zapłaty: {data.finalPrice}zł</b>
            </div>
        }
        </>
     );
}

const DisplayOrderedProduct = (props) => {

    const { store } = useContext(AppContext)

    const data = props.data
    const product = store.store[data.categoryId].products[data.productId];
    return ( 
        <div className="orderedProduct">
         { props.lister+1 }. { product.name } - { data.productCount }szt.
         { product.size !== null ? <p>Rozmiar: {product.size[data.sizeChoosed]}</p> : null }
         { product.meat !== null ? <p>Mięso: {product.meat[data.meatChoosed]}</p> : null }
         { product.sos !== null ? <p>Sos: {product.sos[data.sosChoosed]}</p> : null }
         { data.addedChoosed.length !== 0 ? <p>Dodatki: {data.addedChoosed}</p> : null }
         { data.addedSos.length !== 0 ? <p>Liczba dodatkowych sosów: {data.addedSos.length}szt.</p> : null }
         <b>Cena produktu: {data.finishPrice}zł</b>
        </div>
     );
}

const ListBlank = () => {
    return ( 
        <div className="listBlank">
            <h2>Brak wyników w tej kategorii</h2>
        </div>
     );
}