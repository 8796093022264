import '../Styles/dashboard.css'
import { useState, useContext } from 'react';
import Cookies from 'universal-cookie'
import { AppContext } from '../Context/AppContext'
import { Link, Switch, Route } from 'react-router-dom'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import RoomIcon from '@mui/icons-material/Room';
import ListIcon from '@mui/icons-material/List';

import Main from './DashboardComponents/Main'
import Messages from './DashboardComponents/Messages'
import Metrics from './DashboardComponents/Metrics'
import Payments from './DashboardComponents/Payments';
import List from './DashboardComponents/List'

const Dashboard = () => {

    const cookies = new Cookies();

    const { setIsUserLogged } = useContext(AppContext)

    const [selected, setSelected] = useState(0)
    const handleLogout = () => {
        setIsUserLogged(false)
        cookies.remove('adminlogged')
        cookies.remove('admincode')
    }

    return ( 
        <div className="dashboard__wrapper">
            <nav>
                <ul>
                    <Link to='/admin'><li id={selected === 0 ? 'activeIcon' : null} onClick={() => setSelected(0)}><HomeOutlinedIcon /></li></Link>
                    <Link to='/admin/messages'><li id={selected === 1 ? 'activeIcon' : null} onClick={() => setSelected(1)}><EmailOutlinedIcon /></li></Link>
                    <Link to='/admin/post-code'><li id={selected === 2 ? 'activeIcon' : null} onClick={() => setSelected(2)}><RoomIcon /></li></Link>
                    <Link to='/admin/payments'><li id={selected === 3 ? 'activeIcon' : null} onClick={() => setSelected(3)}><MonetizationOnOutlinedIcon /></li></Link>
                    <Link to='/admin/list'><li id={selected === 4 ? 'activeIcon' : null} onClick={() => setSelected(4)}><ListIcon /></li></Link>
                </ul>
                <label id='logoutIcon' onClick={handleLogout}>
                    <ExitToAppOutlinedIcon  />
                </label>
            </nav>
            <main>
                <Switch>  
                    <Route exact path='/admin'>
                        <Main />
                    </Route>

                    <Route exact path='/admin/messages'>
                        <Messages />
                    </Route>

                    <Route exact path='/admin/post-code'>
                        <Metrics />
                    </Route>

                    <Route exact path='/admin/payments'>
                        <Payments />
                    </Route>

                    <Route exact path='/admin/list'>
                        <List />
                    </Route>
                </Switch>
            </main>
        </div>
     );
}
 
export default Dashboard;