import { createContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { fetchLink } from '../config';

export const AppContext = createContext();

const AppProvider = ({children}) => {

    const [options, setOptions] = useState([])
    const [store, setStore] = useState([])

    useEffect(() => {
        fetch(`https://sziszakebab.pl/getStore`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => setStore(data))
    }, [])

    useEffect(() => {
        const opts = []
        const startAppDate = new Date(2021, 8, 1)
        const today = new Date()
        let dateObjectToAdd = today
        while(dateObjectToAdd.getTime() > startAppDate.getTime()) {
            let year = dateObjectToAdd.getFullYear()
            let month = dateObjectToAdd.getMonth()+1
            let monthName = ''
            switch (month) {
                case 1:
                    monthName = 'Styczeń'
                    break;
                case 2:
                    monthName = 'Luty'
                    break;
                case 3:
                    monthName = 'Marzec'
                    break;
                case 4:
                    monthName = 'Kwiecień'
                    break;
                case 5:
                    monthName = 'Maj'
                    break;
                case 6:
                    monthName = 'Czerwiec'
                    break;
                case 7:
                    monthName = 'Lipiec'
                    break;
                case 8:
                    monthName = 'Sierpień'
                    break;
                case 9:
                    monthName = 'Wrzesień'
                    break;
                case 10:
                    monthName = 'Październik'
                    break;
                case 11:
                    monthName = 'Listopad'
                    break;
                case 12:
                    monthName = 'Grudzień'
                    break;
            
                default:
                    break;
            }
            const dateObjectToPush = `${year},${month},1`
            const labelObjectToPush = `${year} ${monthName}`
            const finalArrayElement = { value: dateObjectToPush, label: labelObjectToPush }
            opts.push(finalArrayElement)
            dateObjectToAdd.setMonth(dateObjectToAdd.getMonth() -1)
        }
        setOptions(opts)
      }, [])

    const cookies = new Cookies();
    useEffect(() => {
        const isLogged = cookies.get('adminlogged')
        const auth = cookies.get('admincode')

        if(isLogged === 'true') {
            fetch(`${fetchLink}/login-auth`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({auth})
            })
            .then(response => response.json())
            .then(data => setCheckedData(data))
        }
        // eslint-disable-next-line
    }, [])

    const setCheckedData = (data) => {
        if(data.isChecked === 'yes') {
            setIsUserLogged(true)
        }   else {
            setIsUserLogged(false)
        }
    }

    const [isUserLogged, setIsUserLogged] = useState(false)  

    //Stats
    const [allEarned, setAllEarned] = useState(0)
    const [allEarnedLoaded, setAllEarnedLoaded] = useState(false)

    const [accountNumber, setAccountNumber] = useState(0)
    const [accountNumberLoaded, setAccountNumberLoaded] = useState(false)

    const [ordersNumber, setOrdersNumber] = useState(0)
    const [ordersNumberLoaded, setOrdersNumberLoaded] = useState(false)

    const [rejectedNumber, setRejectedOrder] = useState(0)
    const [rejectedNumberLoaded, setRejectedOrderLoaded] = useState(false)

    const [cashPayment, setCashPayment] = useState(0)
    const [cashPaymentLoaded, setCashPaymentLoaded] = useState(false)

    const [cartPayment, setCartPayment] = useState(0)
    const [cartPaymentLoaded, setCartPaymentLoaded] = useState(false)

    useEffect(() => {
        fetch(`${fetchLink}/api/stats/allMoneyEarned`, {
            method: 'POST'
        })
        .then(response => response.json())
        .then(({data, responseReady}) => {
            setAllEarned(data)
            setAllEarnedLoaded(responseReady)
        })

        fetch(`${fetchLink}/api/stats/getAccountNumber`, {
            method: 'POST'
        })
        .then(response => response.json())
        .then(({data, responseReady}) => {
            setAccountNumber(data)
            setAccountNumberLoaded(responseReady)
        })

        fetch(`${fetchLink}/api/stats/getOrdersNumber`, {
            method: 'POST'
        })
        .then(response => response.json())
        .then(({data, responseReady}) => {
            setOrdersNumber(data)
            setOrdersNumberLoaded(responseReady)
        })

        fetch(`${fetchLink}/api/stats/rejectedOrdersNumber`, {
            method: 'POST'
        })
        .then(response => response.json())
        .then(({data, responseReady}) => {
            setRejectedOrder(data)
            setRejectedOrderLoaded(responseReady)
        })

        fetch(`${fetchLink}/api/stats/cashPayment`, {
            method: 'POST'
        })
        .then(response => response.json())
        .then(({data, responseReady}) => {
            setCashPayment(data)
            setCashPaymentLoaded(responseReady)
        })

        fetch(`${fetchLink}/api/stats/cartPayment`, {
            method: 'POST'
        })
        .then(response => response.json())
        .then(({data, responseReady}) => {
            setCartPayment(data)
            setCartPaymentLoaded(responseReady)
        })
    }, [])
    //
    
    return ( 
        <AppContext.Provider value={{ store, cartPayment, cartPaymentLoaded, cashPayment, cashPaymentLoaded, options, allEarned, allEarnedLoaded, rejectedNumber, rejectedNumberLoaded, ordersNumber, ordersNumberLoaded, accountNumber, accountNumberLoaded, isUserLogged, setIsUserLogged }}>
            {children}
        </AppContext.Provider>
     );
}
 
export default AppProvider;